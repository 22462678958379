import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Spacer } from "ui-start";
import { FullScreenImage, HeadlineCopy, HeadlineWrapper } from ".";
import Layout from "../components/layout";
import { StyledPageCopy, Wrapper } from "../components/styles";

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      allGoogleSheet {
        edges {
          node {
            about {
              copy
              heroImage
              title
            }
          }
        }
      }
    }
  `);

  const aboutPageData = data?.allGoogleSheet?.edges?.[0]?.node?.about?.[0];

  return (
    <Layout>
      <FullScreenImage imageUrl={aboutPageData?.heroImage}>
        <HeadlineCopy>
          <HeadlineWrapper>
            <h1>{aboutPageData?.title}</h1>
          </HeadlineWrapper>
        </HeadlineCopy>
      </FullScreenImage>
      <Wrapper>
        <Spacer height={32} />
        <StyledPageCopy>{aboutPageData?.copy}</StyledPageCopy>
      </Wrapper>
    </Layout>
  );
};

export default Newsletter;
